/* eslint-disable no-unused-vars*/
import React, { useState } from 'react';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import get from 'lodash/get';
import { Icon, Skeleton } from '@springforcreators/propel-ui';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import sanitizeHtml from 'sanitize-html';
import CustomLink from 'components/CustomLink';
import { useHistory } from 'react-router-dom';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import heroTemplates from './heroTemplates';
import './HeroBanner.scss';

export const HeroBanner = (props) => {
  const {
    getStyles,
    content,
    styles,
    btnStyles,
    products,
    bpIsGT
  } = props;

  const { isFetching } = useGlobalProps();

  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) {
      return [...objValue, ...srcValue];
    }
    return undefined;
  };

  const templateStyles = heroTemplates[get(styles, 'template')];

  // Merge hero template styles & custom styles into single object
  const heroStyles = mergeWith(templateStyles, styles, customizer);

  const heroBtnStyles = getStyles('ctaBtnStyles', heroStyles)
    || getStyles('primary', btnStyles);

  const titleImg = get(content, 'titleImg');
  const title = get(content, 'title');
  const subtitle = get(content, 'subtitle');
  const body = get(content, 'body');
  const ctaText = get(content, 'ctaText');
  const ctaLink = get(content, 'ctaLink') || '#featured-products';
  const ctaOpenInNewWindow = get(content, 'ctaOpenInNewWindow');
  const showSearch = get(content, 'showSearch');
  const featuredImage = getStyles('featuredImage', content);
  const containerBg = getStyles('containerBg', content);
  const template = getStyles('template', heroStyles);

  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');

  const updateSearchTerm = (event) => {
    event.preventDefault();
    history.push({
      pathname: 'search',
      search: `searchterm=${searchTerm}`
    });
  };

  const bgStyles = {
    backgroundImage: `url(${containerBg})`,
    ...getStyles('container', heroStyles)
  };

  const ignoreDefaultStyles = (stylesDefined, defaultStyles) => {
    if (!stylesDefined) return {};
    const filteredStyles = {};
    Object.keys(stylesDefined).forEach((property) => {
      if (stylesDefined[property] !== defaultStyles[property]) {
        filteredStyles[property] = stylesDefined[property];
      }
    });
    return filteredStyles;
  };

  const defaultTitleStyle = { fontSize: '5.0rem' };
  const heroBannerTitleStyles = ignoreDefaultStyles(getStyles('titleStyles', heroStyles), defaultTitleStyle);

  // If user does not enter a containerBg and is using default hero template (5), hide hero (by setting paddingBottom to 0)
  if (!containerBg && template === 5) bgStyles.paddingBottom = '0%';

  return (
    <>
      {isFetching && !products?.length ? (
        <Skeleton
          styles={
            bpIsGT('mobileLg')
              ? { height: 600, width: '100%' }
              : { minHeight: 500, width: '100%' }
          }
        />
      ) : (
        <div
          className="hero__container"
          style={ {
            ...getStyles('bgStyles', heroStyles),
            ...getStyles('textStyles', heroStyles),
            backgroundColor: 'white'
          } }
        >
          <div className="hide-mobile">
            <img src="/assets/eric-herobanner.png" alt="Eric" className="hero__image" />
          </div>
          <div className="hide-desktop">
            <img src="/assets/eric-herobanner-mob.png" alt="Eric" className="hero__image" />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  content: get(state, 'themeData.content.heroBanner'),
  styles: get(state, 'themeData.styles.heroBanner'),
  btnStyles: get(state, 'themeData.styles.button'),
  products: get(state, 'storeProducts.products'),
  ...bpProps(state)
});

const { func, shape } = propTypes;
HeroBanner.propTypes = {
  getStyles: func.isRequired,
  content: shape({}).isRequired,
  styles: shape({}).isRequired,
  btnStyles: shape({}).isRequired,
  products: shape([]).isRequired,
  bpIsGT: func.isRequired
};

export default connect(mapStateToProps)(HeroBanner);
